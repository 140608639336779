<template>
    <div >
        <div class="paddingright20 txt-r">
            <el-button type="primary"  @click="exportExcelFun()" >下载</el-button>
        </div>
        <el-divider>
            <span>主题列表</span>
        </el-divider>
        <el-scrollbar class="ml-10" :style="{'height':(windowHeight-135)+'px'}" tag='ul' >
            <li v-for="item in ecoThemeList" :key="item.ids" style="line-height:30px;">
                <el-checkbox  v-model="item.checked" :label="item.ids" >{{item.name}}</el-checkbox>
            </li>
        </el-scrollbar>
    </div>
</template>
<script>
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import {getEcoThemeList,getAreaEcoListOfTheme,getRegionItemLine} from '@/http/ecoApi'
import {addUserLog} from '@/http/basicsApi.js'
import {exportExcel} from '@/utils/export.js'
import { ElLoading } from 'element-plus'
import {parseTimeOfCycle,zip} from '@/utils'
import store from '@/store'

export default {
    setup() {
        const router=useRouter();
        const windowHeight =ref(document.documentElement.clientHeight);// 可视区的高度
        let regionCode=computed(()=> store.state.curRegionCode)
        
        //获取主题列表
        let ecoThemeList=ref([]);//个性主题列表
        const getEcoThemeListFun= async()=>{
            const result= await getEcoThemeList(regionCode.value);
            console.log(result);
            if(result.code==20000 && result.data){
                ecoThemeList.value=result.data;
                ecoThemeList.value.forEach((t,index)=> {
                    if(index==0){
                        t.checked=true
                    }
                    else{
                        t.checked=false
                    }
                    
                });
            }
        }
        //数据导出到excel
        const exportExcelFun=async ()=>{
            
            // if(downloadCount<areaEcoList.value.length){
            //     ElMessage.warning({
            //     message: '数据正在加载请稍等' 
            //     }); 
            //     return;
            // }
            
            let titleList = [
                {
                label: "指标项",
                prop: "itemName",
                width: "20%",
                },
                {
                label: "时间",
                prop: "date",
                width: "20%",
                },
                {
                label: "单位",
                prop: "itemUnit",
                width: "20%",
                },
                {
                label: "数值",
                prop: "itemValue",
                width: "20%",
                },
            ]
            
            let outfilename = "数据";  // 导出的文件名称
                const loading =  ElLoading.service({
                    lock: true,
                    text: 'Loading',
                    background: 'rgba(0, 0, 0, 0.7)',
                })
                //获取相应主题的数据，相关指标项的本地区的数据
                let downloadDt=[];
                //获取主题的区域经济部件
                let param={
                    themeId:ecoThemeList.value.filter(t=>t.checked==true).map(t=>t.ids).join(','),
                    regionCode:regionCode.value,
                    themeType:1,
                    pageIndex:1,
                    pageSize:1,
                }
                console.log(param);
                const result=await getAreaEcoListOfTheme(param);
                console.log(result);
                if(result.code==20000){
                    result.data.dataList.forEach((el,index)=>{
                        console.log(el.rule);
                        let rule=null;
                        if(el.rule){
                            rule=JSON.parse(el.rule);
                        }
                        getRegionItemLine({regionCode:regionCode.value,itemId:el.items,isForecast:rule?.isForecast==1?true:false}).then(dtRes=>{
                            console.log(dtRes);
                            if(dtRes.code==20000){
                                let itemCycle=dtRes.data.itemCycle;
                                //数据处理
                                dtRes.data.item.forEach(m=>{
                                    if(m.itemValue!=null){
                                    let dt= zip(dtRes.data.date, m.itemValue).map(([date, itemValue]) => ({date:parseTimeOfCycle(date,itemCycle),itemId:m.itemId+'_'+m.objId+'_'+itemCycle+'_'+date, itemValue,itemName:m.itemName,itemUnit:m.itemUnit}));
                                    //console.log(dt);
                                    downloadDt=[...downloadDt,...dt];
                                    if(dtRes.data.item_forecast && dtRes.data.item_forecast.length>0){
                                        let item_forecast=dtRes.data.item_forecast.find(t=>t.itemId==m.itemId);
                                        if(item_forecast && item_forecast.itemValue!=null){
                                            let dt_forecast=zip(dtRes.data.date, item_forecast.itemValue).map(([date, itemValue]) => ( {date:parseTimeOfCycle(date,itemCycle),itemId:item_forecast.itemId+'_'+item_forecast.objId+'_'+itemCycle+'_'+date+'E', itemValue:itemValue,itemName:m.itemName,itemUnit:m.itemUnit}));
                                            dt_forecast=dt_forecast.filter(t=>t.itemValue!=null).map(t=>{
                                                    return {date:t.date,
                                                        itemId:t.itemId, 
                                                        itemValue:t.itemValue+'E',
                                                        itemName:t.itemName,
                                                        itemUnit:t.itemUnit
                                                        }
                                            });
                                            downloadDt=[...downloadDt,...dt_forecast];

                                        }
                                    }
                                    }
                                })
                            }
                            if(index==result.data.totalCount-1){
                                //去重
                                let obj={};
                                const newArray = downloadDt.reduce((item, next)=>{
                                    obj[next.itemId] ? '' : obj[next.itemId] = true && item.push(next);
                                    return item;
                                },[])
                                console.log(newArray);
                                loading.close();
                                // let orderProp = "order_id"; // 序号列对应的字段名称
                                exportExcel(titleList,newArray,outfilename,
                                //orderProp
                                );
                            }
                        })
                    })
                    
                }
                param={ ecoThemeids:ecoThemeList.value.map(t=>t.ids)};
                //记录用户操作日志
                addUserLog(router.currentRoute.value.name,router.currentRoute.value.fullPath,'导出数据',JSON.stringify(param));
                
        }
        getEcoThemeListFun();
        return{
            windowHeight,
            ecoThemeList,
            exportExcelFun
        }
    },
}
</script>