<template>
    <div style="padding:20px;">
        <div style="margin-bottom: 20px;">
            <span class="span-txt">对比年份：</span>
            <!-- <el-tooltip v-if='item.desc' effect="light" :content="item.desc" placement="top-start">
                  <i class="el-icon-warning-outline i-tooltip"></i>
            </el-tooltip> -->
            <el-dropdown v-if='dateList.length>0' @command="selectDate" :max-height='200'>
                <span class="el-dropdown-link">
                    {{selDate}}<i class="iconfont ec-icon-caret-bottom"></i>
                </span>
            <template #dropdown>
                <el-dropdown-menu>
                <el-dropdown-item v-for="date in dateList" :key="date" :command="date">{{date}}</el-dropdown-item>
                </el-dropdown-menu>
            </template>
            </el-dropdown>
            <el-checkbox v-model="isFit" label="自动匹配"  style="margin-left:20px;" />
        </div>
        <span class="span-txt">对比城市：</span>
        <ul class="comparepop-list" v-loading="isLoading" element-loading-text="正在加载中..." :style="{'height':isLoading?'100px':'auto'}">
            <li v-for='region in selRegionList' :key="region.code">
            <span>{{region.name}}</span>
            <i class="iconfont ec-icon-close icon16-close" @click="delRegionFun(region.code)"></i>
            </li>
        </ul>
        <div class="mb-10">
            <el-cascader ref="myCascader" v-if=' !isLoading && selRegionList.length<3' :options="regionList" popper-class="cus-cascader-popper" :props="{'checkStrictly': true}" clearable  @change='regionChangeFun' />
        </div>
        <div >
            <el-button type="primary" :disabled='selRegionList.length==0' @click='pushFun()'>开始对比</el-button>
            <span style="color:gray;" class="ml-10" >最多三个</span>
            <el-link :underline="false" class="btn-link"  @click.prevent='delRegionFun(null)'>清空</el-link>
        </div>
    </div>
</template>
<script>
import {getAllRegionList} from '@/http/basicsApi'
import {getRegionOfDifference} from '@/http/ecoApi'
import { ref } from '@vue/reactivity';
import { useRouter } from 'vue-router';
import { computed } from 'vue';
import store from '@/store'
export default {
    setup() {
        const router=useRouter();//路由
        let regionCode=computed(()=> store.state.curRegionCode)
        //区域比较
        let isShowDiffer=ref(false);//是否显示区域比较选择区域框
        let regionList=ref([]);//地区列表
        const myCascader = ref(null);
        let dateList=ref([]);//日期列表，年份
        let selDate=ref('')//选中的年份
        let isFit=ref(true);//是否自动匹配
        let selRegionList=ref([]);//区域比较选中的地区
        let isLoading=ref(true);//正在加载对比城市
        //获取默认的区域比较的三个地区。城市及跟其GDP上下排名的两个城市，省份时，默认是省会城市
        const getRegionOfDifferenceFun=async()=>{
            selRegionList.value=[];
            let result= await getRegionOfDifference(regionCode.value,selDate.value?selDate.value+'-01-01':'');
            console.log(result);
            if(result.code==20000){

                let year=new Date(result.data.date).getFullYear();
                selDate.value=year;
                for(let i=0;i<10;i++)
                {
                    dateList.value.push(year-i)
                }
                let selRegionAry=result.data.regionCodes.split(',');
                regionList.value.forEach(t=>{
                    t.disabled=true
                    //选择城市
                    if(t.children){
                        t.children.forEach(c=>{
                            c.children=null;
                            c.disabled=false
                            let index=selRegionAry.indexOf(c.value);
                            if(index>-1){
                                c.disabled=true;
                                selRegionList.value.push({
                                    code:c.value,
                                    parent:t.value,
                                    name:t.label+'/'+c.label
                                })
                            }
                        })
                    }
                })
                isLoading.value=false;
            }
        }
        const getAllRegionListFun=async()=>{
            const result=await getAllRegionList();
            //console.log(result);
            if(result.code==20000){
                regionList.value=result.data;
                getRegionOfDifferenceFun();
            }
        }
        //选择对比的年份
        const selectDate=async (date)=>{
            selDate.value=date;
            if(isFit.value){
                isLoading.value=true;
                getRegionOfDifferenceFun();
            }
        }
        //显示区域比较的选择地区框
        const showDifferFun=()=>{
        isShowDiffer.value=!isShowDiffer.value;
        }
        //区域经济——比较区域的选择
        const regionChangeFun=(val)=>{
            console.log(val);
            let selRegion= myCascader.value.getCheckedNodes()[0];
            console.log(selRegion);
            selRegionList.value.push({
                code:selRegion.value,
                parent:selRegion.pathValues[0],
                name:selRegion.text
            })
            //设置已选中的地区不能再选择
            //获取父级索引
            let parentIndex= regionList.value.map(t=>t.value).indexOf(selRegion.pathValues[0]);
            let children= regionList.value[parentIndex].children;
            let index=children.map(t=>t.value).indexOf(selRegion.pathValues[1]);
            regionList.value[parentIndex].children[index].disabled=true;
            //如果面板没有关闭，则手动关闭
            if (myCascader.value.popperVisible) {
            myCascader.value.togglePopperVisible() 
            }
            //将选择的地区放到sessionStorage
            //sessionStorage.setItem('region_differ',selRegionList.value.map(t=>t.code).join(','));
            console.log(selRegionList.value);
        }
        //删除选中的地区
        const delRegionFun=(regionCode)=>{
            let delRegionAry=[];
            if(regionCode){
                let index=selRegionList.value.map(t=>t.code).indexOf(regionCode);
                delRegionAry.push(selRegionList.value[index]);
                selRegionList.value.splice(index,1);
            }
            else{
                delRegionAry=selRegionList.value;
                selRegionList.value=[];
            }
            //对删除的地区取消禁选
            delRegionAry.forEach(t=>{
                //设置已选中的地区不能再选择
                //获取父级索引
                console.log(t);
                let parentIndex= regionList.value.map(t=>t.value).indexOf(t.parent);
                let children= regionList.value[parentIndex].children;
                let index=children.map(t=>t.value).indexOf(t.code);
                regionList.value[parentIndex].children[index].disabled=false;
            })
        }
        //跳转到区域比较页面
        const pushFun=()=>{
            let regionCodes=selRegionList.value.map(t=>t.code).join(',');
            //router.push(`/econo-dev/difference?code=${regionCodes}`);
            let routeData = router.resolve({ path: '/econo-dev/difference', query: {  code: regionCodes } }); 
            window.open(routeData.href, '_blank');
        }
        // //地区字段的监控
        // watch(
        //     ()=>regionCode.value,
        //     (newVal)=>{
        //         //console.log('监听全局变量regionCode',newVal);
        //         if(newVal){
        //             //执行
        //             getRegionOfDifferenceFun();
                    
        //         }
        //     },
        //     {
        //         immediate:true,// 这个属性是重点啦
        //         //deep:true // 深度监听的参数
        //     }
        // )

        //执行
        getAllRegionListFun();
        
        return{
            // 区域经济
            isShowDiffer,
            showDifferFun,
            regionList,
            myCascader,
            dateList,
            selDate,
            isFit,
            selectDate,
            isLoading,
            selRegionList,
            regionChangeFun,
            delRegionFun,
            pushFun
        }
    },
}
</script>
<style lang='scss' scoped>
@import '@/styles/variables.scss';
.comparepop-list{
  margin-bottom: 10px;
  li {
    margin-top: 8px;
    border-bottom: dotted 1px #ccd3e4;
    position: relative;
    line-height: 20px;
    padding: 0 30px 8px 0;
    color: #3B5998;
    font-size: 14px;
    .icon16-close {
        position: absolute;
        right: 6px;
        top: 2px;
        cursor: pointer;
        font-size: 16px;
        color: #d5d5d5;
        &:hover{
        color: #aaa;
        }
    }
  }
}
.btn-link{
  color:$specialtext-color;
  float: right;
  line-height: 40px;
  vertical-align: text-bottom;
  &:hover{
      color: $red;
  }
}
.btn-link.is-disabled {
    color: #C0C4CC;
    cursor: not-allowed;
}
.span-txt {
    line-height: 30px;
    margin-bottom: 5px;
    color: #C0C4CC;
    display:block;
}
</style>