<template>
    <div>
        <div v-if="selfEcoThemeList?.length>0">
            <el-scrollbar style="max-height:580px;" tag='ul' >
            <li v-for="item in selfEcoThemeList" :key="item.ids" :class="{'active':item.ids==selThemeIds}" @click="selThemeFun(item.ids)">
                <span class="mr-10">•</span>{{item.name}}
            </li>
            </el-scrollbar>
            <el-button type="primary" style="margin-left:20px;" :disabled='selThemeIds.length<=0'  @click='pushFun()'>确认</el-button>
            <el-link :underline="false" class="btn-link"  @click.prevent='handleSelect(`/people/areaecoSelf`,`areaecoSelf`)'>创建</el-link>
        </div>
        <div v-else style="text-align:center;margin-top:30px;">
            <img :src="require('@/assets/no-data.png')" alt="no-data" style="width:60%;">
            <p class="no-data"> <span>{{regionName}}</span> 还没有我的主题快去创建吧~</p>
            <el-button type="primary" :disabled='isAddDisabled'  @click='handleSelect(`/people/areaecoSelf`,`areaecoSelf`)'>创建</el-button>
        </div>
        
    </div>
</template>
<script>
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import {getEcoThemeListSelf} from '@/http/ecoApi'
import {getRegionInfo,getUserRightFun} from '@/http/basicsApi.js'
import store from '@/store'
export default {
    setup() {
        const router=useRouter();
        let regionCode=computed(()=> store.state.curRegionCode)
        let selThemeIds=ref('');//选中的主题ID
        let regionName=ref('')//地区名称
        let isAddDisabled=ref(false);//创建按钮是否禁用
        getRegionInfo(regionCode.value).then((res)=>{
          if(res.code==20000){
              regionName.value=res.data.name
            }
        });
        //获取主题列表
        let selfEcoThemeList=ref([]);//个性主题列表
        const getEcoThemeListSelfFun= async()=>{
            const result= await getEcoThemeListSelf(regionCode.value);
            //console.log(result);
            if(result.code==20000 && result.data){
                selfEcoThemeList.value=result.data;
                selThemeIds.value=result.data[0].ids;
            }
            else{
                isAddDisabled.value=true;
            }
        }
        
        //选中主题
        const selThemeFun=(ids)=>{
            selThemeIds.value=ids;
            console.log(selThemeIds.value);
        }
        //跳转到区域比较页面
        const pushFun=()=>{
            let routeData = router.resolve({ path: '/econo-dev/myTheme', query: {  ids: selThemeIds.value } }); 
            window.open(routeData.href, '_blank');
        }
        //功能菜单的选择事件
        const handleSelect=async (path,name)=>{// eslint-disable-line no-unused-vars
            if(await getUserRightFun(name,0)){
                router.push(path)
            }
        }
        //加载我的主题列表
        getEcoThemeListSelfFun();
        return{
            regionName,
            selThemeIds,
            isAddDisabled,
            selfEcoThemeList,
            selThemeFun,
            pushFun,
            handleSelect
        }
    },
}
</script>
<style lang='scss' scoped>
@import '@/styles/variables.scss';
 ul{
     max-height: 80%;
     li{
         line-height: 50px;
         height: 50px;
         margin: 20px;
         border:1px solid $bordercolor;
         padding-left: 20px;
         background: rgba($themeColor,0.02);
         border-radius: 4px;
         cursor: pointer;
         &.active{
            border:1px solid $themeColor;
            color: $specialtext-color;
         }
     }
 }
 .no-data{
     text-align:center;
     font-size:16px;
     color:#99a2aa;
     span{
         color:$specialtext-color;
     }
 }
 .btn-link {
    color: $specialtext-color;
    float: right;
    line-height: 40px;
    vertical-align: text-bottom;
    margin-right: 20px;
}
</style>