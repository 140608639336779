<template>
  
   <div class="common-layout">
    <el-container>
      <el-aside id='theme' :width="isCollapse?'70px': '210px'" style="background:#fff;box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 3px 0px;transition-duration: 0.3s, 0.3s, 0.3s;">
        <div class="menu-title">
          <span v-if='!isCollapse'>主题</span> 
          <i class='iconfont' :class="{'ec-icon-s-fold':!isCollapse,'ec-icon-s-unfold':isCollapse}" @click="handleCollapse"></i>
        </div>
        <el-scrollbar :style="{'height':(windowHeight-135)+'px'}">
        <el-menu
          :default-active="selthemeIds"
          :collapse="isCollapse"
        >
        <!--使用v-if语句进行判断有无children菜单，确定一级菜单是否需要下拉功能-->
          <template v-for="(item,index) in ecoThemeList">
          <el-submenu
              text-color="#fff"
              :index="item.ids"
              :key="item.ids"
              v-if="item.children?.length>0">
            <!--一级菜单在submenu中为下拉状态-->
            <template #title>
              <i  class="iconfont i_icon" :class="item.icon"></i>
              <span>{{item.name}}</span>
            </template>
            <!--二级菜单再次进行v-if判断是否显示二级菜单-->
            <template v-for="item2 in item.children" :key="item2.ids">
              <el-menu-item :index="item2.ids" @click="selThemeFun">
                <span>{{item2.name}}</span>
              </el-menu-item>
            </template>
          </el-submenu>

          <!--一级菜单使用v-else在不在submenu中为非下拉状态-->
          <el-menu-item :index="item.ids" :key="item.ids" v-else @click="selThemeFun(item.ids,index,item.themeType)">
            <i class="iconfont i_icon" :class="item.icon"></i>
            <template #title>
              <span>{{item.name}}</span>
            </template>
          </el-menu-item>
          </template>
        </el-menu>
        </el-scrollbar>
        <!-- <ul>
          <li @click.stop class="menu-item" v-for="(item,index) in ecoThemeList" :key="item.name" :class="{'cur': themeType==1 && index==selIndex}">
            <a @click.prevent="selThemeFun(item.ids,index,1)">
                <!- - <i class="el-icon-folder fontsize14"></i> - ->
                <span>{{item.name}}</span>
            </a>
          </li>
        </ul>
          <div v-if='selfEcoThemeList.length>0'>
             <el-divider class='cus-divider'>个性化</el-divider>
          </div>
          <ul>
            <li @click.stop class="menu-item" v-for="(item,index) in selfEcoThemeList" :key="item.name" :class="{'cur': themeType==2 && index==selIndex}">
              <a @click.prevent="selThemeFun(item.ids,index,2)">
                  <!- - <i class="el-icon-folder fontsize14"></i> - ->
                  <span>{{item.name}}</span>
              </a>
            </li>
          </ul>-->
        <!-- <div v-if='isShowBtn' style="text-align: center;margin-top:10px;">
          <el-button type="primary"  size="small" @click="getEcoThemeListSelfFun" >加载个性主题</el-button>
        </div>  -->
        <p  v-if='ecoThemeList.length<1' style="text-align:center;font-size:16px;color:#99a2aa;">还没有主题快去创建吧~</p>
      </el-aside>
      <el-main  style="position: relative;padding: 0px;">
        <ul ref='areaEcoBox' class="area-eco clearfix" v-infinite-scroll="load" style="overflow: auto;padding: 10px;" :style="{'height': (windowHeight-81)+'px'}">
          <li v-for="item in areaEcoList" :key="item.id">
            <transition>
              <div>
                <span class="span-txt">{{item.title}}</span>
                <el-tooltip v-if='item.desc' effect="light" :content="item.desc" placement="top-start">
                  <i class="iconfont ec-icon-warning i-tooltip"></i>
                </el-tooltip>
                <div v-if='item.showMode=="list_sort"' class="eco-content">
                  <el-table v-loading="item.loadingShow" element-loading-text="正在加载中..."
                    :data="item?.dtList" height='100%' stripe size='small' style="width: 100%" :row-class-name="tableRowClassName">
                    <el-table-column type="index" label="排名" width="80" >
                        <template #default="scope">
                          <!-- <span :class="{'sort-index':scope.$index<9}">{{ scope.$index + 1}}</span>  -->
                          <span >{{ scope.row.sortOrder}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="regionName" label="地区"  />
                    <el-table-column prop="itemValue" :label="item?.itemName?item?.itemName+'('+item?.itemUnit+')':'值'">
                      <template #default="scope">
                        {{ scope.row.itemValue?scope.row.itemValue:'-' }}
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <div v-else class="eco-content">
                  <div  :id='"chart_"+item.id' style="height:100%;width:100%;" v-loading="item.loadingShow" element-loading-text="正在加载中..." >
                  </div>
                </div>
                <span class="eco-date">{{item?.date?'日期：'+item?.date:''}}&nbsp;</span>
                <span class="eco-date" v-if='item?.itemSource && item?.itemSource.length==1'>数据来源：{{item?.itemSource[0]}} </span>
                <el-tooltip v-if='item?.itemSource && item?.itemSource.length>1' effect="dark" :content="item?.itemSource.join('、')" placement="top-start">
                  <span class="eco-date" >数据来源：{{item?.itemSource[0]+'...'}}</span>
                </el-tooltip> 
              </div>
            </transition>
          </li>
          <li v-if='loadNum<pageIndex' style="text-align:center;width:calc(100% - 20px);;box-shadow:none;background: transparent;">正在加载中...</li>
          <li v-if='loadNum>=pageIndex && pageIndex*pageSize>=totalCount' style="margin:20px 10% 10px 10%; width:80%;background-image: linear-gradient(to right,transparent,#002FA7, transparent);height: 1px;box-shadow:none;"></li>
        </ul>
        <!-- <br>
        <p v-if='loadNum<pageIndex' style="text-align:center;color:#aaa;">正在加载中...</p>
        <!- - <p v-if='totalCount>pageIndex*pageSize' style="text-align:center;font-size:large;color:#409eff;">加载更多</p> -- >
        <p v-else style="margin: 20px 10% 0 10%;position: absolute;bottom: 20px; width:80%;background-image: linear-gradient(to right,transparent,#DCDFE6, transparent);height: 1px;">
        </p> -->
      </el-main>
    </el-container>
    <!-- 区域比较 -->
    <div class="open-option-example" v-if='isShowSideMenu'>
      <span v-has="{action:'download'}" id='download'>
        <hr>
        <el-tooltip
          effect='light'
          content="下载"
          placement="left"
        >
          <i  class="iconfont ec-icon-download" style="font-size:24px;" @click="handleSelect('download')" ></i>
           <!-- @click="exportExcelFun('download')" -->
        </el-tooltip>
      </span>
      <span  v-for="item in menuList" :key="item.name" :id='item.name'>
        <hr>
        <el-tooltip
          effect='light'
          :content="item.meta.title"
          placement="left"
        >
          <i  class="iconfont" :class="item.meta.icon" style="font-size:24px;"  @click="handleSelect(item.name)"></i>
        </el-tooltip>
      </span>
    </div>
    <el-drawer v-model="isShowDownload" title="下载"  :size="320" custom-class="cus-drawer" destroy-on-close @closed='closeDrawerFun'>
      <ThemeDtDownload  />
    </el-drawer>
    <el-drawer v-model="isShowDiffer" title="城市对比"  :size="320" custom-class="cus-drawer" destroy-on-close @closed='closeDrawerFun'>
      <SelRegonDiffer  />
    </el-drawer>
    <el-drawer v-model="isShowMyTheme" title="我的主题"  :size="320" custom-class="cus-drawer" destroy-on-close  @closed='closeDrawerFun'>
      <SelMyTheme  />
    </el-drawer>
  </div>

</template>
<script>
// import Header from '@/components/Header.vue'
import SelRegonDiffer from '@/components/SelRegionDiffer.vue'
import SelMyTheme from '@/components/SelMyTheme.vue'
import ThemeDtDownload from '@/components/ThemeDtDownload.vue'
import {getEcoThemeList,getAreaEcoListOfTheme,getRegionItemSort,getRegionItemPie,getRegionItemLine} from '@/http/ecoApi'
import {getRegionInfo,addUserLog,getUserRightFun} from '@/http/basicsApi.js'
import { reactive, ref } from '@vue/reactivity';
import * as echarts from 'echarts'
import {parseTimeOfCycle,colors} from '@/utils'
import { computed, nextTick, onBeforeUnmount, onMounted, watch } from '@vue/runtime-core';
//import {exportExcel} from '@/utils/export.js'
//import { ElMessage  } from 'element-plus'
import store from '../../store'
// 引入封装的nprogress.js
import { start, close } from '@/utils/nprogress';
import { useRoute, useRouter } from 'vue-router'
import {ecoDevIntro} from '@/utils/intro-set'
import{setCookie,getCookie} from '@/utils/index.js'
export default {
  components:{
    // Header,
    SelRegonDiffer,
    SelMyTheme,
    ThemeDtDownload
  },
  setup() {
    
    const windowHeight =ref(document.documentElement.clientHeight);// 可视区的高度
    let router=useRouter();
    let route=useRoute();
    //菜单,对禁用的菜单进行隐藏
    let menuList=reactive(router.getRoutes().find(t=>t.name=='econodev').children.filter(t=>t.name.toLowerCase()!=route.name.toLowerCase()))
    .sort(function (a, b) {//升序
        if(a.meta.sort==null || b.meta.sort==null) return -1
        return a.meta.sort - b.meta.sort;
    });
    //console.log(menuList);
    //选中地区信息
    let regionCode=computed(()=> store.state.curRegionCode);
    let regionName=ref('');
    let echartsAry=ref([]);//echart对象
    let isShowSideMenu=ref(true);//是否显示侧边的菜单
    let isShowDownload=ref(false);//是否显示下载的选择框
    let isShowDiffer=ref(false);//是否显示区域比较的区域选择框
    let isShowMyTheme=ref(false);//是否显示“我的主题”的区域选择框
    let selRegionDifferAry=ref('');//要区域比较的地区列表
    //根据地区获取主题列表
    let ecoThemeList=ref([]);//主题列表
    // let themeType=ref(1);//主题类型，1：通用,2:个性
    // let selIndex=ref(-1);//选中的主题索引
    let selthemeIds=ref('');//选中的主题ID
    let pageSize=ref(6);//每页显示的区域经济部件数
    let pageIndex=ref(1);//页数
    let totalCount=ref(0);//区域经济部件总数
    //let downloadDt=[];//导出数据
    //let downloadCount=0;//判断是否生成完数据集
    let areaEcoBox=ref(null);//区域经济的容器
    const getEcoThemeListFun= async()=>{
      const result= await getEcoThemeList(regionCode.value);
      //console.log(result);
      if(result.code==20000){
        ecoThemeList.value=result.data;
        if(result.data && result.data.length>0){
          selthemeIds.value=result.data[0].ids
          getAreaEcoListOfThemeFun(1);
        }
      }
    }
    //
    //let isShowBtn=ref(false);//是否显示【加载个性主题】
    // const getEcoThemeNumSelfFun=async()=>{
    //   const result= await getEcoThemeNumSelf(regionCode.value);
    //   //console.log(result);
    //   if(result.code==20000 && result.data && result.data>0){
    //     isShowBtn.value=true;
    //   }
    // }
    //获取当前用户的个性主题列表
   
    // let selfEcoThemeList=ref([]);//个性主题列表
    // const getEcoThemeListSelfFun= async()=>{
    //   const result= await getEcoThemeListSelf(regionCode.value);
    //   //console.log(result);
    //   if(result.code==20000 && result.data){
    //     isShowBtn.value=false;
    //     selfEcoThemeList.value=result.data;
    //     ecoThemeList.value.push(
    //       {
    //         name:'个性主题',
    //         ids:'',
    //         children: result.data.map(v=>{
    //                 return {...v,themeType:1}
    //               })
    //       })
    //     console.log(ecoThemeList.value);
    //   }
    // }
    //改变菜单的折叠
    let isCollapse=ref(false);
    const handleCollapse=()=>{
      isCollapse.value=!isCollapse.value;
    }
    let areaEcoList=ref([]);//区域经济列表
    let loadNum=ref(0);
    /**
     * 获取区域经济部件
     * 参数type,1:重新加载，2：翻页，滚动加载
     */
    const getAreaEcoListOfThemeFun= async(type)=>{
      start();
      let param={
        themeId:selthemeIds.value,
        regionCode:regionCode.value,
        themeType:1,
        pageIndex:pageIndex.value,
        pageSize:pageSize.value,
      }
      const result=await getAreaEcoListOfTheme(param);
      if(result.code==20000){
        loadNum.value++;
        //console.log(result.data);
        totalCount.value=result.data.totalCount;
        if(type==1){//重新加载
          areaEcoList.value=  result.data.dataList;
        }
        else{//翻页，滚动加载
          areaEcoList.value=  areaEcoList.value.concat(result.data.dataList);
        }
        areaEcoList.value.forEach((t,index)=>{
          // if(index>0){
          //   return
          // }
          if(index< (pageIndex.value-1)*pageSize.value)
          {
            return
          }
          t.loadingShow=true;
          let param;
          let rule=null;
          if(t.rule){
            rule=JSON.parse(t.rule);
          } 
          if(t.showMode=="list_sort"){//排名
            //console.log(rule);
            let item=JSON.parse(t.items)[0];
            param={
              regionCode:regionCode.value,
              regionRange:rule?.sort?rule.sort:'',
              itemId:item.itemId,
              ObjId:item.objId
            }
            getRegionItemSort(param).then(res=>{
              //console.log(res);
              if(res.code==20000){
                t.dtList=res.data.dataList?res.data.dataList:[];
                t.itemName=res.data.itemName;
                t.itemUnit=res.data.itemUnit;
                t.date=res.data.date && res.data.itemCycle?parseTimeOfCycle(res.data.date,res.data.itemCycle):'';
                t.itemSource=res.data.itemSource?.split('、');
                t.loadingShow=false;
                // let dt= res.data.dataList.map(v=>{
                //   return {...v,title:t.title,date:t.date, itemName:t.itemName,itemUnit:t.itemUnit}
                // });
                
                // downloadDt=[...downloadDt,...dt];
                // downloadCount++;
              }
            });
          }
          else if(t.showMode=="chart_line" || t.showMode=="chart_bar"){//趋势，折线图
            param={
              regionCode:regionCode.value,
              itemId:t.items,
              isForecast:rule?.isForecast==1?true:false
            }
            //console.log('循环',t.showMode);
            getRegionItemLine(param).then(res=>{
            //console.log(res);
              if(res.code==20000){
                let itemCycle=res.data.itemCycle;
                let seriesAry=[];
                let itemSourceAry=[];
                let legendData=[];
                let yAxisAry=[];
                if(rule?.yAxisNum>1){
                  rule.item.forEach((t,i)=>{
                    yAxisAry.push({
                      type: 'value',
                      position:i==0?'left':'right',
                      alignTicks:true,
                      offset:i>1?(i-1)*60:0,
                      axisLine: {
                          show: true,
                          lineStyle: {
                              color: 'gray',
                          }
                      },
                      axisLabel:{
                          margin : 3
                      } 
                    })
                  })
                }
                else {
                  yAxisAry.push({
                    type: 'value'
                  })
                }
                //console.log(rule);
                //console.log(yAxisAry);
                res.data.item.forEach(m=>{
                  itemSourceAry.push(m.itemSource);
                  legendData.push(m.itemName+'('+m.itemUnit+')');
                  //如果是多轴，获取指标项所在多轴中的索引，即第几个轴
                  let index=0;
                  if(rule?.yAxisNum>1){
                    rule.item.forEach((el,ii)=>{
                      //console.log('el',el);
                      //console.log('id', m.id);
                     
                      let ary=el.split(',');
                      //console.log('ary',ary);
                      //console.log(ary.indexOf(m.id.toString()));
                      if(ary.indexOf(m.id.toString())>-1){
                        index=ii;
                        return;
                      }
                    })
                  }
                  seriesAry.push({
                    name:m.itemName+'('+m.itemUnit+')',
                    type:t.showMode.split('_')[1],
                    smooth: true,
                    data:m.itemValue,
                    yAxisIndex:index,
                  })
                  //如果有预测数据，增加预测曲线
                  let forecastList=res.data.item_forecast.find(el=>el.itemId==m.itemId);
                  if(forecastList ){
                      let seriesName='预测值';//m.itemName+'('+m.itemUnit+')E';
                      legendData.push(seriesName);
                      seriesAry.push({
                          name:seriesName,
                          type:t.showMode.split('_')[1],
                          itemStyle:{
                              color:'#EE6666'
                          },
                          lineStyle:(t.showMode=="chart_line"?{
                              type:'dashed',
                          }:{}),
                          smooth: true,
                          data:forecastList.itemValue,
                          yAxisIndex:index
                      })
                  }
                  // if(m.itemValue!=null){
                  //   let dt= zip(res.data.date, m.itemValue).map(([date, itemValue]) => ({date:parseTimeOfCycle(date,itemCycle), itemValue,regionName:regionName.value,title:t.title,itemName:m.itemName,itemUnit:m.itemUnit}));
                  //   downloadDt=[...downloadDt,...dt];
                  // }
                })
                //downloadCount++;
                //指标来源，去重
                itemSourceAry= [...new Set(itemSourceAry.join('、').split('、'))]
                t.itemSource=itemSourceAry;
                //日期
                if(res.data.date && res.data.date.length>0 && res.data.itemCycle){
                  t.date='开始于'+parseTimeOfCycle(res.data.date[0],res.data.itemCycle)
                }
                t.loadingShow=false;
                nextTick(()=>{
                  let myChart =echarts.init(document.getElementById("chart_"+t.id));
                  let option = {
                    color:colors,
                    tooltip: {
                      trigger: 'axis',
                    },
                    legend: {
                      type: 'scroll',
                      pageIconColor:'#79bbff',
                      // pageIconInactiveColor:'#aaa',
                      pageTextStyle:{
                        color:'#9CDCFE',
                      },
                      // top: '5',
                      left: 'center',
                      data:legendData
                      
                    },
                    dataZoom: [
                      {
                        type: 'inside',
                        start: 60,
                        end: 100
                      },
                      {
                        show: true,
                        height: 16,
                        bottom:30,
                        start: 60,
                        end: 100
                      }
                    ],
                    grid: {
                      top: 60,
                      bottom: 80,
                      //left:10,
                      //right:rule?.yAxisNum>1?'15%':20,
                      containLabel:true
                    },
                    xAxis: [
                      {
                        type: 'category',
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLine: {
                            onZero: false,
                        },
                        axisLabel: {
                          formatter: function (params) {
                            return parseTimeOfCycle(params,itemCycle)
                          }
                        },
                        axisPointer: {
                          label: {
                            formatter: function (params) {
                              return parseTimeOfCycle(params.value,itemCycle)
                            }
                          }
                        },
                        data: res.data.date
                      },
                    ],
                    yAxis: yAxisAry,
                    series: seriesAry,
                  };
                  myChart.setOption(option);
                  echartsAry.value.push(myChart);
                });
              }
            })
          }
          else if(t.showMode=="chart_pie"){//占比，饼图
            param={
              regionCode:regionCode.value,
              itemId:t.items
            }
            getRegionItemPie(param).then(res=>{
              //console.log(res);
              if(res.code==20000){
                t.date=res.data?.date && res.data.itemCycle?parseTimeOfCycle(res.data?.date,res.data.itemCycle):'';
                let itemSourceAry=[];
                itemSourceAry=res.data?.dataList.map(m=>m.itemSource);
                //console.log(itemSourceAry)
                if(itemSourceAry && itemSourceAry.length>0){
                  itemSourceAry=[...new Set(itemSourceAry.join('、').split('、'))];
                }
                t.itemSource=itemSourceAry;
                t.loadingShow=false;
                // let dt= res.data.dataList.map(v=>{
                //   return {...v,title:t.title,date:t.date,regionName:regionName.value}
                // });
                
                // downloadDt=[...downloadDt,...dt];
                // downloadCount++;
                nextTick(()=>{
                  let myChart =echarts.init(document.getElementById("chart_"+t.id));
                  let option = {
                    color:colors,
                    graphic: 
                    {
                      type: 'text',
                      z: 100,
                      left: 'center',
                      top: 'middle',
                      silent: true,
                      invisible: !res.data?.dataList ||res.data?.dataList.length== 0?false:true, //是否可见，这里的意思是当没有数据时可见
                      style: {
                        fill: '#fff',
                        //fontWeight: 'bold',
                        text: '暂无数据',
                        fontSize: '16px'
                      }
                    },
                    tooltip: {
                      trigger: 'item',
                      formatter: '{a} <br/>{b} <br/> {c} ({d}%)'
                    },
                    legend: {
                      type: 'scroll',
                      pageIconColor:'#79bbff',
                      // pageIconInactiveColor:'#aaa',
                      pageTextStyle:{
                        color:'#9CDCFE',
                      },
                      // top: '5',
                      left: 'center',
                      data:res.data?.dataList.map(t=>t.itemName+'('+t.itemUnit+')'),
                    },
                    series: [
                      {
                        name: '',
                        type: 'pie',
                        radius: ['40%', '70%'],
                        avoidLabelOverlap: false,
                        emphasis: {
                          itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                          },
                          label: {
                            show: true,
                            // fontSize: '40',
                            fontWeight: 'bold'
                          }
                        },
                        data: res.data?.dataList.map(t=>{
                          return {
                            value:t.itemValue,
                            name:t.itemName+'('+t.itemUnit+')'
                          }
                        }),
                      }
                    ]
                  };
                  myChart.setOption(option);
                  echartsAry.value.push(myChart);
                });
              }
            });
          }
        })
      }
      close();
    }
    //选择不同的主题
    const selThemeFun=(ids)=>{
        pageIndex.value=1;
        selthemeIds.value=ids;
        loadNum.value=0;
        areaEcoBox.value.scrollTop=0;
        // downloadCount=0;
        // downloadDt=[];
        areaEcoList.value=[];
        getAreaEcoListOfThemeFun(1);
        //记录用户操作日志
        let param={ecoThemeIds:ids};
        addUserLog(router.currentRoute.value.name,router.currentRoute.value.fullPath,'选主题',JSON.stringify(param));
      
    }
    //滚动加载
    const load=()=>{
        if(pageIndex.value * pageSize.value > totalCount.value || loadNum.value<pageIndex.value) return
        pageIndex.value++;
        getAreaEcoListOfThemeFun(2);
    }
    // // 滚动加载
    // const lazyLoading= ()=> {
     
    //   const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;// 滚动条滚动时，距离顶部的距离
    //   //const windowHeight = document.documentElement.clientHeight;// 可视区的高度
    //   const scrollHeight = document.documentElement.scrollHeight;// 滚动条的总高度
    //   // 滚动条到底部
    //   if (scrollTop + windowHeight.value >= scrollHeight-400 ) {
    //     if (pageIndex.value * pageSize.value > totalCount.value || loadNum.value<pageIndex.value) return
    //     pageIndex.value++;
    //     getAreaEcoListOfThemeFun(2);
    //   }
    // }
    //
    const tableRowClassName = (obj) => {
      //console.log(obj.row);
      if (obj.row.regionCode === regionCode.value) {
        return 'highlight'
      } 
      return ''
    }
    //功能菜单的选择事件
    const handleSelect=async (name)=>{// eslint-disable-line no-unused-vars
      if(name.toLowerCase()=='download'){
        if(await getUserRightFun(name,1,route.meta.menuId)){
          isShowDownload.value=!isShowDownload.value;
          isShowSideMenu.value=!isShowDiffer.value;
        }
        return false;
      }
      if(await getUserRightFun(name,0)){
        if(name.toLowerCase()=='difference'){
          isShowDiffer.value=!isShowDiffer.value;
        }
        else if(name.toLowerCase()=='mytheme'){
          isShowMyTheme.value=!isShowMyTheme.value;
        }
        isShowSideMenu.value=!isShowDiffer.value;
      }
    }
   //左侧弹窗关闭事件
   const closeDrawerFun=()=>{
     isShowSideMenu.value=true;
   }
   /*
    //数据导出到excel
    const exportExcelFun=async (btnCode)=>{
      //判断是否有权限
      if(!await getUserRightFun(btnCode,1,router.currentRoute.value.meta.menuId)){
        return false;
      }
      if(downloadCount<areaEcoList.value.length){
        ElMessage.warning({
          message: '数据正在加载请稍等' 
        }); 
        return;
      }
      
      let titleList = [
        // {
        //   label: "序号",
        //   prop: "order_id",
        //   width: "4%",
        // },
        {
          label: "标题",
          prop: "title",
          width: "20%",
        },
        {
          label: "地区",
          prop: "regionName",
          width: "9%",
        },
        {
          label: "指标项",
          prop: "itemName",
          width: "20%",
        },
        {
          label: "时间",
          prop: "date",
          width: "20%",
        },
        {
          label: "单位",
          prop: "itemUnit",
          width: "20%",
        },
        {
          label: "数值",
          prop: "itemValue",
          width: "20%",
        },
       ]
    
      let outfilename = "数据";  // 导出的文件名称
      let param={};
        //console.log(ecoThemeList.value);
        //console.log(selIndex.value);
        let item=ecoThemeList.value.find(t=>t.ids==selthemeIds.value);
        outfilename=item.name+'数据';
        param={ecoThemeids:selthemeIds.value};
        //记录用户操作日志
        addUserLog(router.currentRoute.value.name,router.currentRoute.value.fullPath,'导出数据',JSON.stringify(param));
        // let orderProp = "order_id"; // 序号列对应的字段名称
        exportExcel(titleList,downloadDt,outfilename,
          //orderProp
        );
    }
  */
    watch(
      ()=>regionCode.value,
      (newVal,oldVal)=>{
        //console.log('监听全局变量regionCode',newVal);
        if(!oldVal && newVal){//加载时执行
          //执行
          //console.log(newVal);
          getRegionInfo(newVal).then((res)=>{
          if(res.code==20000){
              regionName.value=res.data.name
            }
          });
          getEcoThemeListFun();
          //记录用户操作日志
          addUserLog(router.currentRoute.value.name,router.currentRoute.value.fullPath,'加载','');
        }
      },
      {
        immediate:true,// 这个属性是重点啦
        //deep:true // 深度监听的参数
      }
    )
    
    //自适应
    let listener=()=> {
      echartsAry.value.forEach(element => {
        element.resize();
      });
    }
    window.addEventListener('resize', listener)//监听resize事件
    //window.addEventListener('scroll',lazyLoading);
    onMounted(()=>{
      nextTick(()=>{
          if(!getCookie("intro_ecodev")){
            ecoDevIntro
            // 点击结束按钮后执行的事件
            .oncomplete(() => {
              console.log("点击结束按钮后执行的事件");
              //设置cookie，有效期30天。页面的引导页完成后，30内不再显示引导页
              setCookie("intro_ecodev",1,30)
            }).start();
          }
        })
    })
    onBeforeUnmount(()=>{//销毁之前
        //window.removeEventListener('scroll',lazyLoading)
        window.removeEventListener('resize',listener);
        echartsAry.value.forEach(element => {
          if (element) {
            element.clear(); //清空图表
            element.dispose(); //释放图表组件
            element = null;
          }
        }); 
    })
    return{
      windowHeight,
      menuList,
      regionName,
      ecoThemeList,
      isCollapse,
      handleCollapse,
      areaEcoBox,
      selthemeIds,
      selThemeFun,
      pageIndex,
      pageSize,
      totalCount,
      areaEcoList,
      loadNum,
     
      tableRowClassName,
      load,
      isShowSideMenu,
      isShowDownload,
      isShowDiffer,
      isShowMyTheme,
      closeDrawerFun,
      selRegionDifferAry,
      //exportExcelFun,
      handleSelect
    }
  },
}
</script>
<style lang='scss' scoped>
@import '@/styles/variables.scss';
.span-txt{
    line-height: 30px;
    font-weight: bold;
    padding-left: 10px;
    color: $specialtext-color;
}
.fav-item {
    position: relative;
    padding-left: 20px;
    transition: background-color .3s ease;
    white-space: nowrap;
    overflow: hidden;
    line-height: 44px;
    cursor: pointer;
    a{
        font-size: 14px;
        width: 100%;
        color: #fff;
        justify-content:left;
    }
}
.fav-item.cur,.fav-item.cur:hover {
  background: rgba(63, 148, 253, 0.3);   
  border-left: 3px solid rgba(63,148,253,1);
}
.fav-item:hover {
  background-color: rgba(63, 148, 253, 0.05);
  a{
    color: #fff;
  }
}
.area-eco li{
  float: left;
  width: calc(50% - 20px);
  background: #fff;
  border-radius: 4px;
  margin: 10px;
  position: relative;
  // border: 1px solid $bordercolor;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 3px 0px;
}

.eco-date{
  color: #aaa;
  font-size: 12px;
  display: inline-block;
  line-height: 30px;
  height: 30px;
  padding-left: 10px;
  &:focus{
    outline: none;
  }
}
::v-deep .eco-content{
  height: 400px;
  width: 100%;
  padding: 10px 10px 0 10px;
   .el-loading-mask {
    z-index: 9;
  }
}
.sort-index{
  display: inline-block;
  background: #fc5531;
  width: 20px;
  text-align: center;
  border-radius: 10px;
  height: 20px;
  line-height: 20px;
}


</style>